<template>
  <div class="service-category-setting">
    <PageTitleExtra
      :title="displayText.title"
      cyBtn1="new-category-btn"
      :btn="displayText.btn.create"
      data-testid="page-title_service-category-setting"
      @btnClick="openDialog('create')"
    />

    <FiltersContainer>
      <BaseElInput
        v-model="nameSearch"
        testName="search_category_name"
        clearable
        :placeholder="displayText.search.placeholder"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh(true)" />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <BaseTable v-loading="loading.table" :data="tableData" testName="設備類別">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" :label="displayText.table.name" align="center" />
        <BaseElTableColumn prop="order" :label="displayText.table.order" align="center" />
        <BaseElTableColumn :label="displayText.table.action" fixed="right" width="110" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              :testName="scope.row.name"
              @edit="onEdit(scope.row)"
              @delete="onDelete(scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh(false)"
      />
    </section>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      data-testid="service-category-setting_edit-dialog"
      @close="resetForm()"
    >
      <section>
        <BaseElForm
          ref="formRef"
          :model="formData"
          label-position="top"
          label-width="150px"
          :rules="formRules"
        >
          <BaseElFormItem :label="displayText.dialog.name" prop="name">
            <BaseElInput
              v-model="formData.name"
              testName="formData_name"
              :maxlength="68"
              show-word-limit
              :placeholder="displayText.dialog.placeholder"
            />
          </BaseElFormItem>

          <BaseElFormItem :label="displayText.dialog.order.title" prop="order">
            <template slot="label">
              <FormItemTooltipLabel
                :label="displayText.dialog.order.title"
                class="title"
              >
              {{ displayText.dialog.order.tooltip }}
              </FormItemTooltipLabel>
            </template>
            <BaseElInput v-model="formData.order" testName="formData_order" :placeholder="displayText.dialog.order.placeholder" />
          </BaseElFormItem>

          <BaseElFormItem :label="displayText.dialog.resources.title" prop="resourceItems">
            <template slot="label">
              <FormItemTooltipLabel
                :label="displayText.dialog.resources.title"
                class="title"
              >
              {{ displayText.dialog.resources.tooltip }}
              </FormItemTooltipLabel>
            </template>
            <BaseElSelect
              v-model="formData.resourceItems"
              testName="formData_resourceItems"
              multiple
              value-key="id"
              collapse-tags
              :placeholder="displayText.dialog.resources.placeholder"
              :no-data-text="displayText.dialog.empty"
            >
              <BaseElSelectOption
                v-for="item in resourceList"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </BaseElSelect>
          </BaseElFormItem>
        </BaseElForm>
      </section>

      <div slot="footer">
        <BaseElButton testName="edit-dialog_cancel-btn" plain @click="showDialog = false, resetForm(), formData.services=[]">
          {{ displayText.btn.return }}
        </BaseElButton>
        <BaseElButton
          testName="edit-dialog_confirm-btn"
          type="primary"
          @click="dialogConfirm"
        >
          {{ displayText.btn.confirm }}
        </BaseElButton>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      :title="displayText.dialog.delete.title"
      :content="displayText.dialog.delete.content"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteResourceCategory()"
    />
  </div>
</template>

<script>
import EmptyBlock from '@/components/EmptyBlock.vue'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation/index'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { ref, onMounted, computed, set } from 'vue'
import { get, map, filter } from 'lodash'
import {
  GetResourceCategory,
  GetResourceCategoryCount,
  CreateResourceCategory,
  UpdateResourceCategory,
  DeleteResourceCategory,
  FindResourceCategory,
} from '@/api/resourceCategory'
// Utils
import formUtils from '@/utils/form'
import { useResource } from '@/use/useResource'
import { useTable } from '@/use/table'
import { useBaseForm } from '@/use/useForm'
import { i18n } from '@/plugins/i18n/i18n'

export default {
  name: 'ResourceCategorySetting',
  components: {
    DeleteDialog,
    EmptyBlock,
    FormItemTooltipLabel,
  },
  setup () {
    const { tableData, tableOptions, tableDataCount, fetchDataCount, fetchData, loading, pageStartIndex, shopId } = useTable()
    const { resourceList, getAllResource } = useResource()
    const { formData, formRef, initFormData, checkForm } = useBaseForm()

    initFormData({
      name: '',
      order: 100,
      resourceItems: [],
    })
    const formRules = computed(() => {
      return {
        name: noEmptyRules(i18n.t('resourceCategorySetting.dialog.name.placeholder')),
        order: [noEmptyRules(i18n.t('resourceCategorySetting.dialog.order.placeholder')), isDigitRules(), rangeRules()],
      }
    })
    const dialogTitle = computed(() => {
      const mapTitle = {
        create: i18n.t('resourceCategorySetting.dialog.create.title'),
        update: i18n.t('resourceCategorySetting.dialog.edit.title'),
      }
      return mapTitle[dialogType.value]
    })
    const displayText = computed(() => {
      const data = {
        title: i18n.t('resourceCategorySetting.title'),
        btn: {
          create: i18n.t('common.button.create.text'),
          return: i18n.t('common.button.return.text'),
          confirm: dialogType.value === 'create' ? i18n.t('common.button.create.text') : i18n.t('common.button.save.text'),
        },
        search: {
          placeholder: i18n.t('resourceCategorySetting.search.category.placeholder'),
        },
        table: {
          name: i18n.t('resourceCategorySetting.table.name.title'),
          order: i18n.t('resourceCategorySetting.table.order.title'),
          action: i18n.t('common.table.action.text'),
        },
        dialog: {
          name: i18n.t('resourceCategorySetting.table.name.title'),
          placeholder: i18n.t('resourceCategorySetting.dialog.name.placeholder'),
          order: {
            title: i18n.t('resourceCategorySetting.table.order.title'),
            placeholder: i18n.t('resourceCategorySetting.dialog.order.placeholder'),
            tooltip: i18n.t('resourceCategorySetting.dialog.order.tooltip'),
          },
          resources: {
            title: i18n.t('resourceCategorySetting.dialog.resources.title'),
            tooltip: i18n.t('resourceCategorySetting.dialog.resources.tooltip'),
            placeholder: i18n.t('resourceCategorySetting.dialog.resources.placeholder'),
          },
          empty: i18n.t('common.table.empty.text'),
          delete: {
            title: i18n.t('common.dialog.delete.title'),
            content: i18n.t('common.dialog.delete.content'),
          },
        }
      }
      return data
    })
    const selectRow = ref({
      id: '',
      ShopId: '',
    })
    const showDialog = ref(false)
    const deleteDialog = ref(false)
    const dialogType = ref('')
    const nameSearch = ref('')

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }
    const getTableData = async () => {
      const startIndex = pageStartIndex
      const limit = get(tableOptions, 'pageLimit')
      const payload = {
        shopId: shopId.value,
        name: nameSearch.value === '' ? undefined : nameSearch.value,
        start: startIndex.value,
        limit,
      }
      await Promise.allSettled([
        fetchData(GetResourceCategory, payload),
        fetchDataCount(GetResourceCategoryCount, payload),
      ])
    }
    const openDialog = async (type) => {
      dialogType.value = type
      showDialog.value = true
    }
    const dialogConfirm = async () => {
      loading.table = true
      if (!await checkForm(formRef.value)) return
      const type = dialogType.value
      const payload = {
        shopId: shopId.value,
        id: type === 'update' ? get(selectRow, 'value.id') : undefined,
        name: formData.name,
        order: formData.order,
        resourceItems: map(formData.resourceItems, item => item.id),
      }
      let apiMethod = CreateResourceCategory
      if (type === 'update') apiMethod = UpdateResourceCategory
      const [, err] = await apiMethod(payload)
      if (err) {
        window.$message.error(err)
        loading.value = false
        return
      }
      loading.table = false
      await refresh()
      if (type === 'update') {
        window.$message.success(i18n.t('common.message.updateSuccess'))
      } else {
        window.$message.success(i18n.t('common.message.createSuccess2'))
      }
      showDialog.value = false
    }
    const resetForm = () => {
      formUtils.resetForm(formRef.value)
      formData.name = ''
      formData.order = 100
      formData.resourceItems = []
    }
    const syncFormData = (row) => {
      formData.name = row.name
      formData.order = row.order
    }
    const deleteResourceCategory = async () => {
      try {
        loading.table = true
        await DeleteResourceCategory({
          shopId: shopId.value,
          id: get(selectRow, 'value.id'),
        })
        await refresh()
        loading.table = false
        window.$message.success(i18n.t('common.message.deleteSuccess'))
      } catch (error) {
        window.$message.error({
          message: error || error.message,
        })
      }
      deleteDialog.value = false
      await refresh()
    }
    const onEdit = (row) => {
      selectRow.value = row
      openDialog('update')
      syncFormData(row)
      findResourceCategory()
    }
    const onDelete = async (row) => {
      selectRow.value = row
      deleteDialog.value = true
    }
    const findResourceCategory = async () => {
      const [res, err] = await FindResourceCategory({
        shopId: shopId.value,
        id: get(selectRow, 'value.id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      set(formData, 'resourceItems', filter(res.ResourceItems, item => !item.isRemove))
    }
    onMounted(async () => {
      await Promise.allSettled([
        refresh(),
        getAllResource(),
      ])
    })
    return {
      formData,
      formRef,
      formRules,
      resourceList,
      selectRow,
      nameSearch,
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      refresh,
      showDialog,
      deleteDialog,
      dialogType,
      openDialog,
      dialogConfirm,
      resetForm,
      deleteResourceCategory,
      dialogTitle,
      onEdit,
      onDelete,
      displayText,
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-form-item__label {
  @apply flex;
}
</style>
