import { ref } from 'vue'
import { useShop } from '@/use/shop'
import { useFetch } from '@/use/fetch'
import { GetResourceItem } from '@/api/resource'

export const useResource = () => {
  const { shopId } = useShop()
  const { fetchAll } = useFetch()
  const resourceList = ref([])
  const getAllResource = async () => {
    await fetchAll(
      GetResourceItem,
      { shopId: shopId.value, start: 0, limit: 100 },
      (res) => { resourceList.value = res },
      (err) => { throw new Error(err) }
    )
  }
  return { getAllResource, resourceList }
}
