
import { admin2 } from '@/api/instance'

const baseUrl = (shopId) => {
  return `/${shopId}/resourceItemCategory`
}

//= > 新增設備類別

export const CreateResourceCategory = async ({
  shopId,
  name,
  order,
  resourceItems,
}) => {
  const res = await admin2({
    method: 'post',
    url: baseUrl(shopId),
    data: {
      name,
      order,
      resourceItems,
    },
  })

  return res
}

//= > 更新設備類別

export const UpdateResourceCategory = async ({
  shopId,
  id,
  name,
  order,
  resourceItems,
}) => {
  const res = await admin2({
    method: 'put',
    url: `${baseUrl(shopId)}/${id}`,
    data: {
      name,
      order,
      resourceItems,
    },
  })

  return res
}

//= > 刪除設備類別

export const DeleteResourceCategory = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    method: 'delete',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res
}

//= > 取得設備類別列表

export const GetResourceCategory = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  const res = await admin2({
    method: 'get',
    url: baseUrl(shopId),
    params: {
      start,
      limit,
      name,
    },
  })

  return res
}

//= > 取得設備類別數量

export const GetResourceCategoryCount = async ({
  shopId,
  name,
}) => {
  const res = await admin2({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      name,
    },
  })

  return res
}

//= > 取得設備類別

export const FindResourceCategory = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res
}
